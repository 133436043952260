.setWidth {
  width: 50vw;
  margin: 0 auto;
}

.companyBox {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  justify-content: center;
}

.companyName {
  color: #b40d04;
}

.subName {
  color: blue;
}

.setLogo {
  display: flex;
}

.moveRight {
  display: flex;
  justify-content: end;
}

.menuText {
  color: #000;
}

#shiftDown {
  color: gray;
}

@media (min-width: 576px) and (max-width: 768px) {
  .companyName,
  .subName {
    font-size: 0.8em;
  }
  .companyBox {
    margin-left: 0.3em;
  }
}

@media (max-width: 575.9px) {
  .companyName,
  .subName {
    font-size: 0.6em;
  }

  .companyBox {
    margin-left: 0.1em;
  }
}

/* @media (max-width: 540px) {
  .itemBox {
    height: 10vh;
  }
} */
