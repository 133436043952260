.itemBox {
  width: 100%;
  height: 100vh;
}

.setImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.msgBox {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

#slideOne,
#slideFour {
  margin-bottom: 18%;
}

#slideTwo {
  margin-bottom: 22%;
}

#slideThree {
  margin-bottom: 21%;
}

.msgBox h1 {
  font-family: 'Nunito', sans-serif;
  font-size: 4em;
}

.msgBox p {
  font-size: 1em;
  letter-spacing: 0.2em;
  font-weight: bold;
  padding: 0.5em;
}

@media (min-width: 769px) and (max-width: 992px) {
  .itemBox {
    height: 32vh;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .itemBox {
    height: 30vh;
  }

  #slideOne {
    margin-bottom: 2em;
  }

  #slideTwo,
  #slideThree {
    margin-bottom: 4em;
  }

  #slideFour {
    margin-bottom: 1em;
  }

  .msgBox h1 {
    font-size: 2em;
  }
}

@media (max-width: 575.9px) {
  .itemBox {
    height: 10vh;
  }

  #slideOne,
  #slideTwo,
  #slideThree,
  #slideFour {
    margin-bottom: 0;
  }

  #slideFour h1,
  #slideFour p {
    padding: 0;
    margin: 0;
  }

  .msgBox p {
    font-size: 0.6em;
  }

  .msgBox h1 {
    font-size: 1.1em;
    padding: 0;
  }
}
