.formBox {
  display: flex;
  margin: 0 auto;
  min-height: 60vh;
  padding: 3.5em 3.5em 0 3.5em;
  justify-content: space-between;
}

.form {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.addBox {
  flex: 1;
}

.regInput,
.addInput {
  width: 90%;
  padding: 1em;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  margin-bottom: 1em;
}

.regInput {
  height: 2.5em;
}

.addInput {
  min-height: 7em;
}

@media (max-width: 575.9px) {
  .formBox {
    flex-direction: column;
    padding: 2em 2em;
  }

  .addBox {
    margin-top: 2em;
  }

  .form,
  .addBox {
    width: 100%;
  }
}
