.setServiceBox {
  margin: 10em auto 0 auto;
}

.next,
.prev {
  width: 5vw;
  height: 7.1vh;
  color: #000;
  font-size: 1.8em;
  position: absolute;
  top: 46%;
  z-index: 10;
  background: #fff;
  cursor: pointer;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.card-body {
  height: 18em;
}

.card-body .btn {
  position: absolute;
  bottom: 1em;
}

@media (min-width: 576px) and (max-width: 640px) {
  .setServiceBox {
    margin-top: 18em;
  }
  .card-body {
    height: 20em;
  }
}

@media (max-width: 576px) {
  .card-body .btn {
    right: 1em;
  }
}
