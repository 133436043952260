.footerBox {
  margin: 2em auto 0;
  height: 90vh;
  background-color: #1e1e1c;
  color: rgba(255, 255, 255, 0.5);
}

.itemBox {
  display: flex;
  justify-content: space-between;
  padding: 3.5em 3.5em 0 3.5em;
}

.itemBox a {
  text-decoration: none;
}

.itemBox span {
  font-size: 0.8em;
}

.boxOne {
  flex: 2;
  padding-right: 2em;
}

.boxTwo,
.boxThree,
.boxFour {
  flex: 1;
}

.boxTwo,
.boxThree {
  padding-right: 2em;
}

.copyright {
  text-align: center;
  color: gray;
  margin: 0;
  padding-top: 20vh;
}

.footerColumn,
.socialCol {
  display: flex;
  margin-top: 1em;
}
.footerColumn {
  flex-direction: column;
}

.socialCol {
  justify-content: space-between;
}

.btnMargin,
.iconColor,
.boxOne p {
  color: rgb(168, 165, 165);
}

.btnMargin {
  margin-bottom: 0.8em;
}

@media (min-width: 641px) and (max-width: 768px) {
  .itemBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 576px) and (max-width: 640px) {
  .footerBox {
    height: 100vh;
  }
  .boxOne p {
    font-size: 0.9em;
  }
  .itemBox {
    padding: 1.5em 1.5em 0 1.5em;
  }

  .itemBox span {
    font-size: 0.8em;
  }

  .btnMargin {
    margin-bottom: 0.3em;
    font-size: 0.8em;
  }

  .boxOne,
  .boxTwo,
  .boxThree {
    padding-right: 1em;
  }

  .copyright {
    padding-top: 1vh;
  }

  .copyright p {
    font-size: 0.6em;
  }

  .boxThree.div a {
    margin-bottom: 0;
  }
}

@media (max-width: 575.9px) {
  .itemBox {
    flex-direction: column;
  }
  .footerBox {
    height: auto;
  }

  .boxTwo {
    margin-top: 2em;
  }

  .boxThree,
  .boxFour {
    margin-top: 1em;
  }

  .copyright {
    padding: 1em 0.5em;
  }
}
